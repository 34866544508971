<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                {{ activity.id ? 'Edit' : 'New' }} {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to professional background"
              @click="$router.push({name: route})"
            ><i data-feather="chevron-left" /></a>
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Research lines settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <SearchResearcher :item="activity" />
            <div class="row">
              <div
                :class="activity.type == 2 ? 'col-md-12' : 'col-md-6'"
              >
                <Content v-if="!loading" />
              </div>
              <div class="col-md-6">
                <PlaceOfTalk
                  v-if="!loading && activity.type != 2"
                  :activity="activity"
                />
              </div>
            </div>

            <Multimedia
              v-if="activity.type == 6"
              :item="activity"
            />

            <Trip
              v-if="activity.content && (activity.country && !activity.country.includes(['Spain', 'España', 'Espanya']))"
              :item="activity"
              :is-activity="true"
              :virtual-activity="activity.content.virtual_activity"
              @changeStatus="changeStatus"
            />

            <IcreaRemarks
              :item="activity"
              :visible="true"
            />

            <AddFile
              :id="1"
              :title="'Support documentation'"
              :type="4"
              :visible="false"
              :files="activity.files"
              @saveAnswer="saveAnswer"
            />

            <InternalNotes
              :notes="activity.notes"
              @saveNewNote="saveNewNote"
            />

            <RelatedActivities />
          </div>
        </div>
        <div class="page-actions">
          <button
            v-if="!loading"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" /> <span class="ms-25 align-middle"> Save and Exit</span>
          </button>
          <button
            v-if="loading"
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import InternalNotes from '@/views/back/partials/components/InternalNotes.vue'
import AddFile from '../../admin/senior-call/components/AddFile.vue'
import PlaceOfTalk from './components/PlaceOfTalk.vue'
import RelatedActivities from './components/RelatedActivities.vue'
import IcreaRemarks from '../../partials/components/IcreaRemarks.vue'
import Content from './components/Content.vue'
import Trip from './components/Trip.vue'
import Multimedia from './components/Multimedia.vue'
import SearchResearcher from '../../partials/components/SearchResearcher.vue'

export default {
  components: {
    AddFile,
    PlaceOfTalk,
    InternalNotes,
    IcreaRemarks,
    Content,
    SearchResearcher,
    Trip,
    Multimedia,
    RelatedActivities,
  },
  data() {
    return {
      activityId: this.$route.params.id,
      loading: true,
      route: null,
      title: 'activity',
      errors: '',
    }
  },
  computed: {
    ...mapGetters({
      activity: 'activities/item',
      users: 'users/fakeUsers',
      statuses: 'status/statuses',
    }),
  },
  async mounted() {
    if (this.activityId) {
      await this.$store.dispatch('activities/fetchId', this.activityId)
      if (!this.activity.content) {
        this.activity.content = {
          host: null,
          recipient_institution: null,
        }
      }
    } else {
      await this.$store.dispatch('activities/clear')
    }

    // Get the type of the activity based on route
    const name = this.$router.currentRoute.name.split('.')[1]
    await this.$store.dispatch('status/filterStatus', 'Activities')

    switch (name) {
      case 'conferences':
        this.route = 'researcher.conferences.index'
        this.title = 'Conference'
        this.activity.type = 1
        break
      case 'phds':
        this.route = 'researcher.phds.index'
        this.title = 'PhD & Master Theses and TFG'
        this.activity.type = 2
        break
      case 'courses':
        this.route = 'researcher.courses.index'
        this.title = 'Course or seminar'
        this.activity.type = 3
        break
      case 'managerial':
        this.route = 'researcher.managerial.index'
        this.title = 'managerial activity'
        this.activity.type = 4
        break
      case 'stays-research':
        this.route = 'researcher.stays-research.index'
        this.title = 'stay of research'
        this.activity.type = 5
        break
      case 'disseminations':
        this.route = 'researcher.disseminations.index'
        this.title = 'dissemination'
        this.activity.type = 6
        break
      default:
    }

    this.loading = false
  },
  methods: {
    async save() {
      this.loading = true

      const checkDates = await this.checkDates()

      if (!checkDates) {
        this.loading = false
        return
      }

      try {
        if (this.activityId) {
          await this.$store.dispatch('activities/update', { id: this.activityId, data: this.activity })
        } else {
          await this.$store.dispatch('activities/create', this.activity)
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      this.loading = false
      if (this.errors === '' && this.activity.id) {
        Vue.swal('Activity updated successfully!', '', 'success').then(() => {
          this.$router.push({ name: this.route })
        })
      }
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    saveNewNote(note) {
      this.activity.notes.push(note)
    },
    saveAnswer(data) {
      this.activity.files_new = data.data
    },
    async checkDates() {
      const resp = await this.$store.dispatch('activities/checkDates', { ...this.activity })

      let check = true

      if (resp.status === 'warning') {
        await Vue.swal({
          title: 'Are you sure?<br>',
          html: resp.message.join('<br>'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes, apply!',
        }).then(result => {
          if (!result.isConfirmed) {
            check = false
          }
        })
        this.changeStatus(true)
      } else if (resp.status === 'error') {
        Vue.swal(resp.message, '', 'error')
        check = false
      }

      return check
    },
    changeStatus(val) {
      if (val) {
        const status = this.statuses.find(e => (e.status.toLowerCase().includes('required authorization') || e.status.toLowerCase().includes('authorization required')))
        if (status) {
          this.activity.content.status = status
        }
      } else {
        const status = this.statuses.find(e => (e.status.toLowerCase().includes('authorization not required')))

        if (status) {
          this.activity.content.status = status
        } else {
          this.activity.content.status = null
        }
      }
    },
  },
}
</script>
